import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button as BootstrapButton } from 'react-bootstrap';
import { Link } from 'gatsby';
import axios from 'axios';
import { navigate } from "gatsby";

const ReviewOrder = ({ shippingInfo, paymentInfo, orderItems, onConfirm, onBack }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoToOrdersDisabled, setIsGoToOrdersDisabled] = useState(false);
  const [orderError, setOrderError] = useState(null);
  const [isConfirmOrderDisabled, setIsConfirmOrderDisabled] = useState(false);

  const handleConfirmOrder = async () => {
    setIsConfirmOrderDisabled(true);
    try {
      await onConfirm();
      setShowModal(true);
      setOrderError(null);
    } catch (error) {
      console.error('Order confirmation error:', error);
      setOrderError('There was an error confirming your order. Please try again.');
      setIsConfirmOrderDisabled(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.removeItem('cartItems');
    localStorage.setItem('cartCount', 0);
    //navigate('/catalogue');
    navigate('/');
  };

  const handleClearCart = async () => {
    const userId = localStorage.getItem('userId');
    try {
      await axios.delete(`${process.env.GATSBY_API_URL}/cart/clear`, { data: { userId } });
      localStorage.removeItem('cartItems');
      localStorage.removeItem('cartCount');
      window.dispatchEvent(new Event('storage'));
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };

  const handleProceedAndConfirm = () => {
    //setIsLoading(true)
    // Check for valid distributor IDs in order items
    const invalidItems = orderItems.some(item => !item.drug || !item.drug._id);
    if (invalidItems) {
      setOrderError('Some order items have invalid distributor IDs.');
      return;
    }

    handleClearCart();
    handleConfirmOrder();
  };

  const handleGoToOrdersClick = () => {
    setIsGoToOrdersDisabled(true);
    navigate('/orders');
  };

  return (
    <Container>
      <Subheading>Shipping Address:</Subheading>
      <Paragraph>{shippingInfo.address}</Paragraph>
      
      <Subheading>Payment Method:</Subheading>
      <Paragraph>{paymentInfo.payment}</Paragraph>

      <Subheading>Phone Number:</Subheading>
      <Paragraph>{paymentInfo.phoneNumber}</Paragraph>

      <Subheading>Order Items:</Subheading>
      <List>
        {orderItems.map((item) => (
          <ListItem key={item.drug._id}>
            {item.drug.productTradeName} - Quantity: {item.quantity}
          </ListItem>
        ))}
      </List>

      {orderError && <ErrorMessage>{orderError}</ErrorMessage>}
      
      <Button onClick={onBack}>Back</Button>
      <Button onClick={handleProceedAndConfirm} disabled={isConfirmOrderDisabled}>Confirm Order</Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your order has been placed successfully. The distributor will get back to you on email/call</Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <ModalButton 
              variant="secondary" 
              onClick={handleCloseModal}
            >
              Close
            </ModalButton>
          </Link>
          <Link to="/orders">
            <ModalButton 
              variant="primary"
              onClick={handleGoToOrdersClick}
              disabled={isGoToOrdersDisabled}
            >
              Go to Orders
            </ModalButton>
          </Link>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReviewOrder;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Subheading = styled.h3`
  margin-top: 20px;
  color: #027a79;
`;

const Paragraph = styled.p`
  margin: 5px 0;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #03989e;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 10px 5px;

  &:hover {
    background-color: #027a79;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const ModalButton = styled(BootstrapButton)`
  &.btn-secondary {
    background-color: #027a79;
    border: none;
    &:hover {
      background-color: #025c57;
    }
  }
  &.btn-primary {
    background-color: #03989e;
    border: none;
    &:hover {
      background-color: #027a79;
    }
  }
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  margin-top: 10px;
`;
