import React, { useState } from 'react';
import styled from 'styled-components';
import ShippingAddress from '../components/ShippingAddress';
import PaymentMethod from '../components/PaymentMethod';
import ReviewOrder from '../components/ReviewOrder';
import axios from 'axios';
import Layout from '../components/layout';
import SEO from '../components/seo';

const fetchDistributorDetails = async (distributorId) => {
  const response = await fetch(`${process.env.GATSBY_API_URL}/distributors/${distributorId}`);
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

const fetchWholesalerDetails = async (wholesalerId) => {
  const response = await fetch(`${process.env.GATSBY_API_URL}/wholesalers/${wholesalerId}`);
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }
  return response.json();
};

const createOrderPayload = (orderData) => {
  const orderItems = orderData.orderItems.map((item) => ({
    productId: item.productId,
    productTradeName: item.productTradeName,
    quantity: item.quantity,
    sellerType: item.sellerType,
    sellerId: item.sellerId,
    sellerName: item.sellerName,
    sellerEmail: item.sellerEmail,
  }));

  return {
    userId: orderData.userId,
    shippingAddress: orderData.shippingAddress,
    paymentMethod: orderData.paymentMethod,
    phoneNumber: orderData.phoneNumber,
    orderItems,
    status: 'Pending',
  };
};

const createOrderItems = async (cartItems) => {
  const detailsPromises = cartItems.map(item => {
    if (item.sellerType === 'distributor' && item.selectedSeller) {
      return fetchDistributorDetails(item.selectedSeller);
    } else if (item.sellerType === 'wholesaler' && item.selectedSeller) {
      return fetchWholesalerDetails(item.selectedSeller);
    } else {
      return Promise.resolve(null);
    }
  });

  try {
    const details = await Promise.all(detailsPromises);

    return cartItems.map((item, index) => {
      const detail = details[index];
      let sellerId;

      if (item.sellerType === 'distributor') {
        sellerId = item.selectedSeller;
      } else if (item.sellerType === 'wholesaler') {
        sellerId = item.selectedSeller;
      }

      if (!sellerId) {
        console.error(`SellerId is missing for item:`, item);
      }

      return {
        productId: item.drug._id,
        sellerId,
        sellerType: item.sellerType,
        sellerName: detail ? detail.name : 'Unknown',
        sellerEmail: detail && detail.handler ? detail.handler.email : 'Unknown',
        productTradeName: item.drug.productTradeName,
        quantity: item.quantity,
      };
    });
  } catch (error) {
    console.error('Error fetching seller details:', error);
    throw error;
  }
};





const Checkout = () => {
  const [shippingAddress, setShippingAddress] = useState({});
  const [paymentMethod, setPaymentMethod] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isShippingSubmitted, setIsShippingSubmitted] = useState(false);
  const [isPaymentSubmitted, setIsPaymentSubmitted] = useState(false);

  const handleShippingSubmit = (address) => {
    setShippingAddress(address);
    setIsShippingSubmitted(true);
  };

  const handlePaymentSubmit = (paymentData) => {
    setPaymentMethod(paymentData.payment);
    setPhoneNumber(paymentData.phoneNumber);
    setIsPaymentSubmitted(true);
  };

  const handleConfirmOrder = async () => {
    const userId = localStorage.getItem('userId');
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    try {
      const orderItems = await createOrderItems(cartItems);
      const orderData = {
        userId,
        shippingAddress,
        paymentMethod,
        phoneNumber,
        orderItems,
        status: 'Pending'
      };

      const payload = createOrderPayload(orderData);

      const response = await axios.post(`${process.env.GATSBY_API_URL}/checkout`, payload);
      console.log('Order Created:', response.data);
      
      // Clear cart items from local storage
      localStorage.removeItem('cartItems');

      // Save the new order to the local storage
      const existingOrders = JSON.parse(localStorage.getItem('orders')) || [];
      localStorage.setItem('orders', JSON.stringify([...existingOrders, response.data]));

      // Optionally, you can store the latest order separately
      localStorage.setItem('latestOrder', JSON.stringify(response.data));
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  return (
    <Layout>
      <SEO
        title="Secure Checkout - Complete Your Purchase"
        description="Finalize your order with Patameds by providing your shipping details and payment method. Enjoy a seamless checkout experience with secure transactions."
        keywords="secure checkout, online payment, shopping cart, order completion, Patameds"
      />
      <Container>
        <h1>Checkout</h1>
        <Step>
          {!isShippingSubmitted ? (
            <>
              <StepHeader>Shipping Address</StepHeader>
              <StepContent>
                <ShippingAddress onSubmit={handleShippingSubmit} />
              </StepContent>
            </>
          ) : !isPaymentSubmitted ? (
            <>
              <StepHeader>Payment Method</StepHeader>
              <StepContent>
                <PaymentMethod 
                  onSubmit={handlePaymentSubmit} 
                  onBack={() => setIsShippingSubmitted(false)} 
                />
              </StepContent>
            </>
          ) : (
            <>
              <StepHeader>Review Order</StepHeader>
              <StepContent>
                <ReviewOrder 
                  shippingInfo={shippingAddress} 
                  paymentInfo={{ payment: paymentMethod, phoneNumber }} 
                  orderItems={JSON.parse(localStorage.getItem('cartItems')) || []}
                  onConfirm={handleConfirmOrder} 
                  onBack={() => setIsPaymentSubmitted(false)} 
                />
              </StepContent>
            </>
          )}
        </Step>
      </Container>
    </Layout>
  );
};

export default Checkout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Step = styled.div`
  margin-bottom: 20px;
`

const StepHeader = styled.div`
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #03989e;
`

const StepContent = styled.div`
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
`
