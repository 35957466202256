import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const PaymentMethod = ({ onSubmit, onBack }) => {
  // Initialize state with values from local storage or default empty values
  const [payment, setPayment] = useState(() => {
    const savedPayment = localStorage.getItem('payment');
    return savedPayment ? JSON.parse(savedPayment) : '';
  });

  const [phoneNumber, setPhoneNumber] = useState(() => {
    const savedPhoneNumber = localStorage.getItem('phoneNumber');
    return savedPhoneNumber ? JSON.parse(savedPhoneNumber) : '';
  });

  // Save payment and phoneNumber data to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('payment', JSON.stringify(payment));
  }, [payment]);

  useEffect(() => {
    localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber));
  }, [phoneNumber]);

  const handlePaymentChange = (e) => {
    setPayment(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ payment, phoneNumber });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Select onChange={handlePaymentChange} value={payment} required>
        <option value="">Select a method</option>
        <option value="Pay on Delivery">Pay on Delivery</option>
        <option value="Mpesa">Mpesa</option>
      </Select>
      <Label>
        Phone Number:
        <Input 
          type="tel" 
          value={phoneNumber} 
          onChange={handlePhoneChange} 
          required 
          placeholder="Enter your phone number" 
        />
      </Label>
      <ButtonGroup>
        <Button type="button" onClick={onBack}>Back</Button>
        <Button type="submit">Next</Button>
      </ButtonGroup>
    </Form>
  );
};

export default PaymentMethod;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const Select = styled.select`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  &:focus {
    border-color: #03989e;
    outline: none;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 1em;
`;

const Input = styled.input`
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  &:focus {
    border-color: #03989e;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #03989e;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
  &:hover {
    background-color: #027a79;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
