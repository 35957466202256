import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ShippingAddress = ({ onSubmit }) => {
  // Initialize state with values from local storage or default empty values
   const isBrowser = typeof window !== "undefined"
  const [address, setAddress] = useState(() => {
    const savedAddress = isBrowser && window.localStorage.getItem('address');
    return savedAddress ? JSON.parse(savedAddress) : {
      name: '',
      address: '',
      city: '',
      zip: '',
      state: '',
      country: '',
    };
  });

  // Save address data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('address', JSON.stringify(address));
  }, [address]);

  // Retrieve and set user data from local storage on component mount
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setAddress(prevAddress => ({
        ...prevAddress,
        name: `${user.firstName} ${user.lastName}`,
        country: user.placeOfWork?.countryOfWork || '',
      }));
    }
  }, []);

  const handleChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(address);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        {/* <Label htmlFor="name">Full Name</Label>
        <Input
          id="name"
          name="name"
          placeholder="Full Name"
          value={address.name}
          onChange={handleChange}
          required
        /> */}
        <Label htmlFor="country">Country</Label>
        <Input
          id="country"
          name="country"
          placeholder="Country"
          value={address.country}
          onChange={handleChange}
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="address">Address</Label>
        <Input
          id="address"
          name="address"
          placeholder="Address"
          value={address.address}
          onChange={handleChange}
          required
        />
        {/* <Label htmlFor="city">City</Label>
        <Input
          id="city"
          name="city"
          placeholder="City"
          value={address.city}
          onChange={handleChange}
          required
        />
        <Label htmlFor="state">State</Label>
        <Input
          id="state"
          name="state"
          placeholder="State"
          value={address.state}
          onChange={handleChange}
          required
        /> */}
      </InputGroup>
      {/* InputGroup>
        <Label htmlFor="zip">Zip Code</Label>
        <Input
          id="zip"
          name="zip"
          placeholder="Zip Code"
          value={address.zip}
          onChange={handleChange}
          required
        />
      </InputGroup> */}
      <Button type="submit">Next</Button>
    </Form>
  );
};

export default ShippingAddress;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  flex-basis: 100%;
  font-size: 0.9em;
  color: #333;
  margin-bottom: 5px;
  display: block;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  &:focus {
    border-color: #03989e;
    outline: none;
  }
  &::placeholder {
    color: #aaa;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #03989e;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #027a79;
  }
`;
